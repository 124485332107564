<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Общая информация
        </h4>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row class="mb-2">
            <b-col md="12">
              <b-form-checkbox
                v-model="schedule.isTempDisabled"
                name="temp-checkbox"
                class="custom-control-primary"
              >
                Временно отключить тренировку
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row v-if="schedule.isTempDisabled">
            <b-col md="12">
              <b-form-group label="Сообщение для пользователей">
                <b-form-input
                  v-model="schedule.note"
                  placeholder="Сообщение для пользователей"
                  aria-describedby="input-live-feedback"
                  :state="schedule.note ? null : false"
                />
                <b-form-invalid-feedback id="input-live-feedback">
                  Обязательное поле
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div class="card-body">
        <div>
          <label
            class="font-size"
            for="datepicker"
          >День недели</label>
          <v-select
            id="datepicker"
            v-model="schedule.weekday"
            :options="weekdays"
            :reduce="option => option"
          />

          <!-- <b-form-datepicker
            id="datepicker"
            v-model="schedule.startDate"
            placeholder="YYYY-MM-DD"
            locale="ru"
            class="mb-2"
          /> -->
        </div>
      </div>
      <div class="card-body">
        <!-- <label class="font-size" for="timeString">Укажите Время</label>
        <v-select
          id="timeString"
          v-model="schedule.startDate"
          :options="times"
          :reduce="(option) => option"
        /> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <b-form-group label="Время проведения">
                <b-form-input
                  v-model="schedule.startDate"
                  placeholder="Время проведения"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <b-form-group label="Адрес проведения">
                <b-form-input
                  v-model="schedule.address"
                  placeholder="Адрес проведения"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Вид спорта
        </h4>
      </div>
      <span class="bv-no-focus-ring col-form-label pt-0 label">
        Выберите вид спорта
      </span>
      <v-select
        v-model="sportOption"
        :close-on-select="true"
        :options="sportOptions"
        :reduce="sportOption => sportOption"
        label="name"
        input-id="name"
        :value="sportOption"
        class="select"
        @search="(search, loading) => getKindsOfSport(search, loading)"
      />
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Вид тренировки
        </h4>
      </div>
      <span class="bv-no-focus-ring col-form-label pt-0 label">
        Выберите вид тренировки
      </span>
      <v-select
        v-model="workoutOption"
        :close-on-select="true"
        :options="workoutOptions"
        :reduce="workoutOption => workoutOption"
        label="type"
        input-id="type"
        :value="workoutOption"
        class="select"
        @search="(search, loading) => getWorkouts(search, loading)"
      />
    </div>

    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Тренер
        </h4>
      </div>
      <span class="bv-no-focus-ring col-form-label pt-0 label">
        Выберите тренера
      </span>
      <v-select
        v-model="trainerOption"
        :close-on-select="true"
        :options="trainerOptions"
        multiple
        :reduce="trainerOption => trainerOption"
        label="name"
        input-id="name"
        :value="trainerOption"
        class="select"
        @search="(search, loading) => getTrainers(search, loading)"
      />
    </div>

    <div class="card">
      <div class="card-header" />
      <div class="card-body">
        <h4 class="card-title">
          Описание
        </h4>
        <div class="my-1">
          Описание
        </div>
        <quill-editor v-model="schedule.description" />
      </div>
    </div>

    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-button"
        :disabled="isloading"
        @click="saveItem"
      >
        <b-spinner
          v-if="isloading"
          small
        />
        {{ isloading ? 'Сохранение...' : 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  VBPopover,
  BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import crud_module from '../../../libs/crud_module'

const schedule_module = crud_module({
  entity_name: 'schedule',
})
const kind_of_sport_module = crud_module({
  entity_name: 'kind-of-sport',
})
const workouts_module = crud_module({
  entity_name: 'workout',
})
const trainers_module = crud_module({
  entity_name: 'trainer',
})

export default {
  name: 'SchedulePage',
  components: {
    vSelect,
    quillEditor,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    BFormDatepicker,
    BFormCheckbox,
    BFormInvalidFeedback,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
      isloading: false,
      schedule: {},
      sportOptions: [],
      sportOption: null,
      workoutOptions: [],
      workoutOption: {},
      trainerOptions: [],
      trainerOption: [],
      debounce: null,
      // times: [
      //   '07:00',
      //   '08:00',
      //   '09:00',
      //   '10:00',
      //   '11:00',
      //   '12:00',
      //   '13:00',
      //   '14:00',
      //   '15:00',
      //   '16:00',
      //   '17:00',
      //   '18:00',
      //   '19:00',
      //   '20:00',
      //   '21:00',
      //   '22:00',
      // ],
      weekdays: [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье',
      ],
    }
  },
  computed: {
    input(event) {
      this.$emit('input', event.target.value)
    },
    search(search, loading) {
      this.$emit('search', search, loading)
    },
  },
  watch: {
    trainerOption() {
      if (!this.trainerOption.length) this.trainerOptions = []
      if (this.trainerOption.length) {
        this.schedule.trainer_ids = this.trainerOption.map(
          trainer => trainer.id,
        )
      }
    },
    sportOption() {
      if (!this.sportOption) this.sportOptions = []
      if (this.sportOption.id) this.schedule.kindOfSportId = this.sportOption.id
    },
    workoutOption() {
      if (!this.workoutOption) this.workoutOptions = []
      if (this.workoutOption.id) this.schedule.workout_id = this.workoutOption.id
    },
  },
  async created() {
    if (this.$route.params.id === 'new') {
      return
    }
    try {
      await schedule_module
        .fetchItem(this.$route.params.id, {
          query: {
            admin: '1',
          },
        })
        .then(result => {
          this.schedule = result.data
          this.sportOption = result.data.kindOfSport
          this.trainerOption = result.data.trainers
          this.workoutOption = result.data.workout
        })
    } catch (e) {
      console.error(e)
    }
  },
  async mounted() {
    await kind_of_sport_module.fetchItems().then(result => {
      this.sportOptions = result.data.rows
    })

    await workouts_module.fetchItems().then(result => {
      this.workoutOptions = result.data.rows
    })

    await trainers_module.fetchItems().then(result => {
      this.trainerOptions = result.data.rows
    })
  },
  methods: {
    debounceSearch(search, loading, fn) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(fn, 600)
    },
    getKindsOfSport(search, loading) {
      this.debounceSearch(search, loading, async () => {
        try {
          await kind_of_sport_module
            .fetchItems({ filterText: search })
            .then(result => {
              this.sportOptions = result.data.rows
              loading(false)
            })
        } catch (e) {
          console.error(e)
        }
      })
    },
    getWorkouts(search, loading) {
      this.debounceSearch(search, loading, async () => {
        try {
          await workouts_module
            .fetchItems({ filterText: search })
            .then(result => {
              this.workoutOptions = result.data.rows
              loading(false)
            })
        } catch (e) {
          console.error(e)
        }
      })
    },
    getTrainers(search, loading) {
      this.debounceSearch(search, loading, async () => {
        try {
          await trainers_module
            .fetchItems({ filterText: search })
            .then(result => {
              this.trainerOptions = result.data.rows
              loading(false)
            })
        } catch (e) {
          console.error(e)
        }
      })
    },
    saveItem() {
      if (this.schedule.isTempDisabled && !this.schedule.note) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Сообщение для пользователей не заполнено',
            variant: 'danger',
          },
        })
        return
      }
      if (this.$route.params.id === 'new') {
        this.create()
      } else {
        this.update()
      }
    },
    async update() {
      try {
        await schedule_module
          .updateItem(this.schedule.id, this.schedule)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Расписание сохранено',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'schedules' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    async create() {
      try {
        await schedule_module
          .createItem(this.schedule)
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Расписание добавлено',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'schedules' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.select {
  padding: 0 21px 21px;
  width: 100%;
}

.label {
  padding-left: 21px;
}

.font-size {
  font-size: inherit;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
